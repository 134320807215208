import React from 'react'
import IdleTimer from 'react-idle-timer'
import { Auth } from "aws-amplify";

class IdleComponent extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            timeout:1000 * 60 * 60 * 4,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this.handleOnAction.bind(this)
        this.onActive = this.handleOnActive.bind(this)
        this.onIdle = this.handleOnIdle.bind(this)
    }

    handleOnAction = event => {
        if(this.idleTimer.getElapsedTime() > (this.state.timeout/2)) {
            this.idleTimer.reset()
            this.props.onActiveRefresh();
        }
    }

    handleOnActive = event => {
        // Component active
    }

    handleOnIdle = event => {
        Auth.signOut({ global: true });
    }

    render() {
        return <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
    }

}

export default IdleComponent;