import React from 'react';
import leddarOne from '../oneBanner.png';
import logo from '../logo.png';
import { ToastContainer } from 'react-toastify';
import { Auth } from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';

const LeddarHeader = (props) => {

    const logout = () => {
        Auth.signOut({ global: true });
        props.setStep('login');
    }

    return (
        <header className="App-header">
            <div className="banner-content">
                <div className="logo-div">
                    <a href="https://www.leddartech.com">
                        <img src={logo} className='leddar-logo-app' alt="leddartech logo" width="400" />
                    </a>
                </div>
                <h1 className="banner-title">Leddar PixSet Dataset</h1>
                <div className="logo-div-desc">
                    <p>The First Full-Waveform Flash LiDAR Dataset for Autonomous Vehicle R&amp;D</p>
                    <p>Leddar PixSet is a new, publicly available dataset for autonomous driving R&amp;D that contains data from a full AV sensor suite (Cameras, LiDARs, Radar, IMU), and includes full-waveform data from the Leddar Pixell, a 3D solid-state flash LiDAR sensor. PixSet provides an opportunity for 3D computer vision to go beyond LiDAR point clouds with a full-waveform LiDAR dataset.</p>
                </div>

            </div>
            <div className="banner-img">
                <img src={leddarOne} className="App-logo" alt="logo" />
            </div>
            <ToastContainer position="top-center" />
            {props.signout ?
                <button onClick={logout} className="signout">logout</button> :
                <div></div>
            }
        </header>

    )
}

export default LeddarHeader