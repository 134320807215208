import React, { useEffect, useState } from 'react';
import FileRow from './fileRow'
import { Auth, API } from "aws-amplify";
import './fileList.css'

const FileList = props => {

    const [files, setFiles] = useState([])
    
    useEffect(() => {
        getFiles();
    }, []);

    const getFiles = () => {
        Auth.currentSession().then(session => {
            let payload = {
                headers: { Authorization: `Bearer ${session.idToken.jwtToken}` }
            };
            API.post('s3ListFiles', '/metadata', payload).then(response => {
                setFiles(response)
            })
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div id="fileList" className="fileList">
            {files.length > 0 ? (
                files.map(file => (
                    <FileRow key={file.dataset} file={file} url={props.cloudFrontUrl} />
                ))
            ) : (<div key="nofiles">
                <div className="empty"></div>
            </div>)}
        </div>
    )
}

export default FileList;