import React from 'react';
import { SignUp } from 'aws-amplify-react';
import { CountryDropdown } from 'react-country-region-selector';
import "./auth.css"
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import PasswordField from './PasswordField';
import EmailField from './EmailField';

class LeddarSignUp extends SignUp {
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            agreement: false,
            password: '',
            email: '',
            given_name: '',
            family_name: '',
            company_institution: ''
        };
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    onChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    setEmail = value => {
        this.setState({email: value});
    }

    signUp = async e => {
        e.preventDefault();
        try {
            await Auth.signUp({
                username: this.state.email,
                password: this.state.password,
                attributes: {
                    email: this.state.email,
                    "given_name": this.state.given_name,
                    "family_name": this.state.family_name,
                    "custom:company_institution": this.state.company_institution,
                    "custom:country": this.state.country
                }
            })
            this.props.setStep('confirmSignUp', '', this.state.password);
        } catch (err) {
            toast.error(err.message)
        }
    }

    signin = e => {
        this.props.setStep('login');
    }

    acceptLicense = e => {
        this.setState({ agreement: e.target.checked })
    }

    canSignup = () => {
        const { agreement, password, email, given_name, family_name, company_institution, country } = this.state;

        if (!agreement ||
            password === '' ||
            email === '' ||
            given_name === '' ||
            family_name === '' ||
            company_institution === '' ||
            country === '') {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const canSignup = this.canSignup();
        return (<div className="auth-section">
            <form className="auth-div" onSubmit={this.signUp}>
                <EmailField setEmail={this.setEmail} label="Email *"/>
                <label>First name *</label>
                <input name="given_name" placeholder="first name" type='text' onChange={this.onChange} className="form-field" />
                <label>Last name *</label>
                <input name="family_name" placeholder="last name" type='text' onChange={this.onChange} className="form-field" />
                <PasswordField setPassword={this.onChange} passwordFieldName="Password" />
                <label>Enterprise / Institution *</label>
                <input type="text" placeholder="Enterprise / Institution" name='company_institution' onChange={this.onChange} className="form-field" />
                <label>Country *</label>
                <CountryDropdown type="country" name='country' value={this.state.country}
                    onChange={(val) => this.selectCountry(val)} className="form-field" />
                <span className="licenseAgreement">
                    <input type="checkbox" onClick={this.acceptLicense} id="licenseAgreement" />
                    <label htmlFor="licenseAggrement">&nbsp;I have read and accept the <a href="https://leddartech.com/download/dataset-licence-agreement/" target="_blank" rel="noopener noreferrer">Licence Agreement</a></label></span>
                <div className="controls">
                    <input type="submit" value="Sign Up" disabled={canSignup} />
                    <div className="btnGroup">
                        <button onClick={this.signin} >Sign In</button>
                    </div>
                </div>
            </form>
        </div>)
    }
}

export default LeddarSignUp;