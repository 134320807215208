import React from 'react';
import Fade from "react-reveal/Fade";

const emailValidation = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

class EmailField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emailValid: 'form-field',
            email: ''
        };
    }

    onEmailChange = e => {
        let emailErrorMessage = '';
        let validClass = true;
        if (!e.target.value.match(emailValidation)) {
            emailErrorMessage = 'Not a valid email.';
            validClass = false;
        }
        if (validClass) {
            this.props.setEmail(e.target.value)
        } else {
            this.props.setEmail('');
        }
        this.setState({ email: e.target.value, emailValid: "form-field" + (validClass ? '' : ' is-invalid'), emailInvalidMessage: emailErrorMessage })
    }

    render() {
        return (
            <div className="emailField">
                <label>{this.props.label}</label>
                <input type="text" placeholder="abc@abc.com" name='email' onChange={this.onEmailChange} className={this.state.emailValid} />
                <div className="errorArea">
                    <Fade left collapse when={this.state.emailInvalidMessage}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.emailInvalidMessage}
                            <br />
                        </div>
                    </Fade>
                </div>
            </div>)
    }
}

export default EmailField;