import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import './auth.css'
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import EmailField from './EmailField';

class LeddarConfirmSignUp extends ConfirmSignUp {

    state = { email: '', validationCode: '' };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    setEmail = value => {
        this.setState({email: value});
    }

    resend = e => {
        this.props.resend(e, this.state.username);
    }

    confirm = async e => {
        e.preventDefault();
        const { email, validationCode } = this.state
        Auth.confirmSignUp(email, validationCode).then(response => {
            this.props.login(email, this.props.password)
        }).catch(err => {
            console.log(JSON.stringify(err))
            toast.error(err.message);
        });
    }

    render() {
        return (<div className="auth-section">
            <form className="auth-div" onSubmit={this.confirm}>
                <p>Please check your Email for confirmation code.</p>
                <EmailField label="Username *" setEmail={this.setEmail} />
                <label>Validation Code *</label>
                <input type="text" placeholder="Enter your validation code" name='validationCode' onChange={this.onChange} className="form-field" />
                <div className="controls">
                    <input type="submit" value="Confirm" />
                    <button onClick={this.resend}>Resend Code</button>
                </div>
            </form>
        </div>)
    }
}

export default LeddarConfirmSignUp;