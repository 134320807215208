const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_ZJGYBIX5l",
    "aws_user_pools_web_client_id": "67tiji8hftiofe580ht687ols1",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "s3ListFiles",
            "endpoint": "https://ilhkwjkc44.execute-api.ca-central-1.amazonaws.com/Prod",
            "region": "ca-central-1"
        }
    ]
};

export default awsmobile;