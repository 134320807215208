import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";

var images = [{ img: "thumbnail_1.jpg" }, { img: "thumbnail_2.jpg" }, { img: "thumbnail_3.jpg" }, { img: "thumbnail_4.jpg" }]

const MiniCarousel = props => {

    const imagesUrl = images.map(img => {
        return {
            url: '/dataset_thumbnails/'.concat(props.dataset).concat('/').concat(img.img)
        }
    })


    return (
        <div className="roulette" >
            <SimpleImageSlider images={imagesUrl}
                height={100}
                width={200}
                showBullets={true}
                showNavs={false} />
        </div>)
}

export default MiniCarousel;