import React from 'react';
import Amplify from "aws-amplify";
import './App.css';
import config from "./aws-exports";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

import LeddarSignIn from './components/authentication/LeddarSignIn'
import LeddarSignUp from './components/authentication/LeddarSignUp'
import MainPage from './components/MainPage'
import LeddarHeader from './components/LeddarHeader';
import LeddarConfirmSignUp from './components/authentication/LeddarConfirmSignup';
import LeddarPasswordReset from './components/authentication/LeddarPasswordReset';

Amplify.configure(config);
Auth.configure(config);

class App extends React.Component {

  state = { step: 'login' }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.setStep('loggedIn');
    }).catch(err => {
      // not authenticated
    })
  }

  setStep = (newStep, user, password) => {
    this.setState({ step: newStep, user: user, password: password })
  }

  login = async (username, password) => {
    await Auth.signIn({ username, password }).then(data => {

      if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setStep('passwordReset', data)
      } else {
        this.setStep('loggedIn');
      }
    }).catch(err => {
      console.log(JSON.stringify(err))
      if (err.code === 'UserNotConfirmedException') {
        this.setStep('confirmSignUp', '', password)
      } else if (err.code === 'PasswordResetRequiredException') {
        this.setStep('passwordReset')
      } else {
        toast.error(err.message);
      }
    });
  }

  resend = async (e, email) => {
    e.preventDefault();
    try {
      await Auth.resendSignUp(email);
      toast.success("Code resent to your email.")
    } catch (err) {
      console.log(JSON.stringify(err))
      toast.error(err.message);
    }
  }

  render() {
    return (
      <div className="App">
        <LeddarHeader signout={this.state.step === 'loggedIn'} setStep={this.setStep} />
        {this.state.step === 'login' &&
          <LeddarSignIn setStep={this.setStep} login={this.login} />
        }
        {this.state.step === 'signup' &&
          <LeddarSignUp setStep={this.setStep} />
        }
        {this.state.step === 'confirmSignUp' &&
          <LeddarConfirmSignUp setStep={this.setStep} resend={this.resend} login={this.login} password={this.state.password} />
        }
        {this.state.step === 'passwordReset' &&
          <LeddarPasswordReset setStep={this.setStep} resend={this.resend} user={this.state.user} login={this.login} />
        }
        {this.state.step === 'loggedIn' &&
          <MainPage />
        }
      </div>)
  }
}

export default App;
