import React from 'react';
import Fade from "react-reveal/Fade";

class PasswordField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordValid: 'form-field',
            passwordLowerCase: '',
            passwordMinimumCharacter: '',
            passwordUpperCase: '',
            passwordNumeric: '',
            passwordSpecialCharacter: ''
        }
    }

    onPasswordChange = e => {
        let validClass = true;
        let passwordLowerCase = '';
        let passwordMinimumCharacter = '';
        let passwordUpperCase = '';
        let passwordNumeric = '';
        let passwordSpecialCharacter = '';
        if (e.target.value.length < 8) {
            validClass = false;
            passwordMinimumCharacter = 'Password must have at least 8 characters';
        }
        if (!e.target.value.match(/[a-z]/g)) {
            validClass = false;
            passwordLowerCase = 'Password must include at least one lowercase character';
        }
        if (!e.target.value.match(/[A-Z]/g)) {
            validClass = false;
            passwordUpperCase = 'Password must include at least one uppercase character';
        }
        if (!e.target.value.match(/[!@#$%^&*)(+=._-]/g)) {
            validClass = false;
            passwordSpecialCharacter = 'Password must include at least on special character (@, #, $, *, etc.)'
        }
        if (!e.target.value.match(/[0-9]/g)) {
            validClass = false;
            passwordNumeric = 'Password must include at least on number'
        }
        if(validClass) {
            this.props.setPassword(e);
        }
        this.setState({
            password: e.target.value,
            passwordValid: "form-field" + (validClass ? '' : ' is-invalid'),
            passwordLowerCase: passwordLowerCase,
            passwordMinimumCharacter: passwordMinimumCharacter,
            passwordUpperCase: passwordUpperCase,
            passwordNumeric: passwordNumeric,
            passwordSpecialCharacter: passwordSpecialCharacter
        })
    }

    render() {
        return (
            <div className="password-field">
                <label className="tip">{this.props.passwordFieldName} *
                <span className="tiptext">(minimum 8 characters, a-z,A-Z,0-9, symbols)</span>
                </label>
                <input type="password" placeholder="(minimum 8 characters, a-z,A-Z,0-9, symbols)" name='password' onChange={this.onPasswordChange} className={this.state.passwordValid} />
                <div className="errorArea">
                    <Fade left collapse when={this.state.passwordLowerCase}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.passwordLowerCase}
                        </div>
                    </Fade>
                    <Fade left collapse when={this.state.passwordMinimumCharacter}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.passwordMinimumCharacter}
                        </div>
                    </Fade>
                    <Fade left collapse when={this.state.passwordUpperCase}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.passwordUpperCase}
                        </div>
                    </Fade>
                    <Fade left collapse when={this.state.passwordNumeric}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.passwordNumeric}
                        </div>
                    </Fade>
                    <Fade left collapse when={this.state.passwordSpecialCharacter}>
                        <div className="invalid-feedback"
                            style={{ display: 'block' }}
                        >
                            {this.state.passwordSpecialCharacter}
                        </div>
                    </Fade>
                </div>
            </div>);
    }
}

export default PasswordField;