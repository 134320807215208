import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import MiniCarousel from './miniCarousel'
import Badge from 'react-bootstrap/Badge';
import 'bootstrap/dist/css/bootstrap.min.css';


const FileRow = props => {

    const createTags = (tags, label, variant) => {
        let tagsList = [];
        let i = 0;

        let title = <span key={tags}>{label} : </span>

        for (let item in props.file[tags]) {
            i++;
            tagsList.push(<Badge key={i} variant={variant}>{item} : {props.file[tags][item]}</Badge>)
        }

        let result = [];
        result.push(<div key={uuidv4()} className="tagTitle">{title}</div>)
        result.push(<div key={uuidv4()} className="tagsSection">{tagsList}</div>)
        return result;
    }

    return (
        <div key={props.file.dataset} className="card">
            <div className="card-header">
                <span>{props.file.dataset}</span>
            </div>
            <div className="card-body">
                <div className="card-description-wrapper">
                    <MiniCarousel dataset={props.file.dataset} />
                    <div className="card-description">
                        <div>
                            {createTags("categories", "Categories", "primary")}
                        </div>
                        <div>
                            {createTags("time", "Time", "secondary")}
                        </div>
                        <div>
                            {createTags("weather", "Weather", "light")}
                        </div>
                        <div>
                            {createTags("location", "Location", "info")}
                        </div>
                    </div>
                </div>
                <a href={props.url.concat('/dataset/').concat(props.file.dataset).concat('.zip')} target="_blank" rel="noopener noreferrer">
                    <div className="download-arrow">
                        <span className="hyperspan"></span>
                        <div className="download-btn" >
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cloud-arrow-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default FileRow;