import React, { useEffect, useState } from 'react';
import { Auth, API } from "aws-amplify";
import Cookies from 'universal-cookie';
import FileList from './FileList'
import Idle from './IdleComponent'

const MainPage = props => {

    const [cloudFrontUrl, setCloudFrontUrl] = useState('');

    useEffect(() => {
        getCookie();
    }, []);

    const getCookie = () => {
        var cookie = new Cookies();
        Auth.currentSession().then(session => {
            let payload = {
                headers: { Authorization: `Bearer ${session.idToken.jwtToken}` }
            };
            API.post('s3ListFiles', '/cookie', payload).then(response => {
                cookie.set('CloudFront-Key-Pair-Id', response['CloudFront-Key-Pair-Id'], {
                    path: '/',
                })
                cookie.set('CloudFront-Policy', response['CloudFront-Policy'], {
                    path: '/',
                })
                cookie.set('CloudFront-Signature', response['CloudFront-Signature'], {
                    path: '/',
                })
                setCloudFrontUrl(response['cloudfronturl']);
            })
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div className="main-app-page">
            <div className="body">
                <FileList cloudFrontUrl={cloudFrontUrl} />
            </div>
            <Idle onActiveRefresh={getCookie} />
        </div>
    )
}

export default MainPage;