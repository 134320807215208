import React from 'react';
import { SignIn } from 'aws-amplify-react';
import './auth.css'
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import EmailField from "./EmailField";

class LeddarSignIn extends SignIn {

    state = { email: '', password: '' };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    signup = () => {
        this.props.setStep('signup');
    }

    setEmail = value => {
        this.setState({email: value})
    }

    login = e => {
        e.preventDefault();
        this.props.login(this.state.email, this.state.password)
    }

    resetPassword = async e => {
        e.preventDefault();
        await Auth.forgotPassword(this.state.email).then(result => {
            this.props.setStep('passwordReset');
        }).catch(err => {
            toast.error(err.message)
        })
    }

    render() {
        return (<div className="auth-section">
            <form className="auth-div" onSubmit={this.login}>
                <EmailField label="Username *" setEmail={this.setEmail} />
                <label>Password *</label>
                <input type="password" placeholder="Enter your password" name='password' onChange={this.onChange} className="form-field" />
                <div className="controls">
                    <input type="submit" value="Login" />
                    <button onClick={this.signup}>Sign Up</button>
                </div>
                <div>Forgot your password? <button className="lookLikeALink" onClick={this.resetPassword} >reset it!</button></div>
            </form>
        </div>)
    }
}

export default LeddarSignIn;