import React from 'react';
import './auth.css'
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import PasswordField from './PasswordField'
import EmailField from './EmailField';

class LeddarPasswordReset extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            validationCode: '',
            password: '',
            oldPassword: ''
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    setEmail = value => {
        this.setState({email: value})
    }

    resend = e => {
        this.props.resend(e, this.state.email);
    }

    changePassword = async e => {
        e.preventDefault();
        try {
            if (!this.props.user) {
                await Auth.forgotPasswordSubmit(this.state.email, this.state.validationCode, this.state.password);
                this.props.login(this.state.email, this.state.password)
            } else {
                Auth.signIn(this.state.email, this.state.oldPassword).then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        const requiredAttributes = user.challengeParam.userAttributes;
                        if (!requiredAttributes.family_name) {
                            requiredAttributes.family_name = "incognito"
                        }
                        if (!requiredAttributes.given_name) {
                            requiredAttributes.given_name = "incognito"
                        }
                        Auth.completeNewPassword(
                            user,
                            this.state.password,
                            requiredAttributes
                        ).then(user => {
                            this.props.login(this.state.email, this.state.password)
                        }).catch(e => {
                            toast.error(e)
                        });
                    }
                });
            }
        } catch (err) {
            console.log(JSON.stringify(err))
            toast.error(err.message);
        }
    }

    isSubmitDisabled = () => {
        const { email, password, validationCode, oldPassword } = this.state;

        if (password === '' ||
            email === '' ||
            (validationCode === '' && oldPassword === '')) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const isSubmitDisabled = this.isSubmitDisabled();
        return (<div className="auth-section">
            <form className="auth-div" onSubmit={this.changePassword}>
                <p>Please check your Email for confirmation code.</p>
                <EmailField label="Username *" setEmail={this.setEmail} />
                {this.props.user ? (
                    <div>
                        <label>Old password *</label>
                        <input type="password" placeholder="Enter your old password" name='oldPassword' onChange={this.onChange} className="form-field" />
                    </div>
                ) : (
                        <div>
                            <label>Confirmation code *</label>
                            <input type="text" placeholder="Enter your confirmation code" name='validationCode' onChange={this.onChange} className="form-field" />
                        </div>
                    )}
                <PasswordField setPassword={this.onChange} passwordFieldName="New password" />
                <div className="controls">
                    <input type="submit" value="Change password" disabled={isSubmitDisabled} />
                    {!this.props.user &&
                        < button onClick={this.resend}>Resend Code</button>
                    }
                </div>
            </form>
        </div >)
    }
}

export default LeddarPasswordReset;